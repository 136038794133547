import Navbar from "./components/Navbar.js";
import About from "./components/About.js";
import Skills from "./components/Skills.js";
import Experience from "./components/Experience.js";
import Projects from "./components/Projects.js";
import Footer from "./components/Footer.js";

function App() {
  return (
    <main className="app min-vh-100">
      <Navbar />
      <About />
      <Skills />
      <Experience />
      <Projects />
      <Footer />
    </main>
  );
}

export default App;
