export default function Navbar() {
  return (
    <nav className="p-3 sticky-top">
      <div className="container">
        <div className="nav">
          <a href="#about" className="logo h4 w-50 text-dark">
            Sean Lightfoot
          </a>
          <div className="w-50 links">
            <a className="text-dark nav-links h5" href="#about">
              About
            </a>
            <a className="text-dark nav-links h5" href="#skills">
              Skills
            </a>
            <a className="text-dark nav-links h5" href="#experience">
              Experience
            </a>
            <a className="text-dark nav-links h5" href="#projects">
              Projects
            </a>
          </div>
          <span
            className="toggle-button"
            onClick={() => {
              const links = document.querySelector(".links");
              links.classList.toggle("active");
            }}
          >
            <span className="bar text-dark mb-1"></span>
            <span className="bar text-dark mb-1"></span>
            <span className="bar text-dark"></span>
          </span>
        </div>
      </div>
    </nav>
  );
}
