import Pdf from "../assets/resume.pdf";

export default function Footer() {
  return (
    <section className="p-3">
      <div className="foot-links d-flex justify-content-center align-items-center">
        <a href="https://github.com/blackinferno1000" target="_blank">
          <i className="bi bi-github p-2"></i>
        </a>
        <a href="https://www.linkedin.com/in/seanlightfoot/" target="_blank">
          <i className="bi bi-linkedin p-2"></i>
        </a>
        <a href={Pdf} className="resume p-2" target="_blank">
          Resume
        </a>
      </div>
      {/* <p className="text-light p-2 mt-3 d-flex justify-content-center align-items-center">
        Sean Lightfoot &copy; 2021
      </p> */}
    </section>
  );
}
