export default function Skills() {
  return (
    <section className="p-5" id="skills">
      <div className="container">
        <h1 className="display-4 pb-3">Skills</h1>
        <div className="row">
          <div className="col-md-4">
            <h3 className="h2">Languages</h3>
            <div className="skill-list p-3">
              <p>Javascript</p>
              <p>CSS3/SCSS</p>
              <p>HTML5</p>
              <p>SQL</p>
              <p>Python</p>
              <p>C#</p>
            </div>
          </div>
          <div className="col-md-4">
            <h3 className="h2 pt-3">Libraries</h3>
            <div className="skill-list p-3">
              <p>React.js</p>
              <p>React Native</p>
              <p>Vue.js</p>
              <p>Angular</p>
              <p>Express.js</p>
              <p>Bootstrap</p>
              <p>TailwindCSS</p>
              <p>Jest</p>
              <p>Storybook</p>
              <p>Webpack</p>
              <p>GraphQL</p>
              <p>Redux</p>
              <p>Gatsby.js</p>
              <p>Next.js</p>
              <p>.NET</p>
            </div>
          </div>
          <div className="col-md-4">
            <h3 className="h2 pt-3">Tools</h3>
            <div className="skill-list p-3">
              <p>Node.js</p>
              <p>Git</p>
              <p>PostgreSQL</p>
              <p>MongoDB</p>
              <p>Jira</p>
              <p>Circleci</p>
              <p>Jenkins</p>
              <p>Photoshop</p>
              <p>Figma</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
