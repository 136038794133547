export default function About() {
  return (
    <div className="container" id="about">
      <div className="row align-items-center">
        <div className="col-md-6">
          <div className="pic"></div>
        </div>
        <div className="col-md-6">
          <h1 className="display-5">
            Hi, I'm Sean. I’m a software developer based in Chicago.
          </h1>
          <h3 className="h3">
            I specialize in front-end development and have full-stack and mobile
            development experience.
          </h3>
        </div>
      </div>
    </div>
  );
}
