export default function Experience() {
  return (
    <section className="p-5 text-dark" id="experience">
      <div className="container">
        <h1 className="display-4 pb-3">Experience</h1>
        <div className="d-flex flex-column job-list p-2">
          <Job
            company="GoDaddy"
            title="SDE Intern"
            time="May 2020 - Aug 2020"
            description="Developed and maintained various components and applications across the GoDaddy codebase.
Frontend and backend work in React, HTML, CSS, Javascript, Express.js, and other tools when needed.
Unit testing with jest, testing React components with storybook, and publishing packages."
          />
          <Job
            company="Forbes"
            title="Front-end Developer Intern"
            time="Jan 2019 - May 2019"
            description="Developed on Forbes new CMS using Angular, Javascript, SASS, and Quill.js.
Unit testing using Jasmine on Angular components and services.
Developed an internal application using Gatsby, React, Node.js, HTML, and CSS."
          />
          <Job
            company="RIT"
            title="SG Web Developer"
            time="Jan 2020 - Dec 2020"
            description="Work on various web applications maintained by the student government, using Vue.js, Python, and PostgreSQL."
          />
          <Job
            company="RIT"
            title="Web Development Teaching Assistant"
            time="Aug 2018 - Jan 2020"
            description="Helped students learn web development foundational skills(HTML, CSS, JS, PHP, APIs, etc)."
          />
        </div>
      </div>
    </section>
  );
}

function Job(props) {
  return (
    <div className="p-2">
      <h3 className="display-5">{props.company}</h3>
      <h4 className="h3">{props.title}</h4>
      <h4 className="h4">{props.time}</h4>
      <p className="h5">{props.description}</p>
    </div>
  );
}
