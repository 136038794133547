import freshGoods from "../assets/freshGoods.PNG";
import budgetTracker from "../assets/budgetTracker.PNG";
import ColorGame from "../assets/colorGame.PNG";
import Shortly from "../assets/shortly.png";

export default function Projects() {
  return (
    <section className="p-5 text-dark" id="projects">
      <div className="container">
        <h1 className="display-4 pb-3">Projects</h1>
        <div className="row project-list">
          <Project
            url="https://sharp-kirch-daa82b.netlify.app/"
            alt="budgetTracker"
            src={budgetTracker}
            title="Budget Tracker"
          />
          <Project
            url="https://focused-colden-fdb038.netlify.app/"
            alt="Shortly"
            src={Shortly}
            title="Shortly"
          />
          <Project
            url="https://modest-pare-937545.netlify.com/"
            alt="Color Game"
            src={ColorGame}
            title="Color Game"
          />
          <Project
            url="https://60e534162107e85f487fda57--thirsty-feynman-eb5fa4.netlify.app/"
            alt="freshGoods"
            src={freshGoods}
            title="FreshGoods"
          />
        </div>
      </div>
    </section>
  );
}

function Project(props) {
  return (
    <div className="col-md-6 p-5">
      <a href={props.url} className="display-4 text-dark" target="_blank">
        <img src={props.src} alt={props.alt} className="img-fluid" />
        {props.title}
      </a>
    </div>
  );
}
